import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUserCredentials } from "shared/models/IUserCredentials";
import { ILoginCredentials } from "shared/models/ILoginCredentials";
import { UserRoles } from "./UserRoles";
import { UserCredential, signOut } from "firebase/auth";
import axios, { AxiosResponse } from "axios";
import store, { AppDispatch, auth } from "index";
import { ApplicationState } from "app/redux/rootReducer";
import { showToast } from "shared/services/toastService";
import { switchLanguage } from "shared/helpers/getLanguages";

const INITIAL_STATE: IUserCredentials = {
	userId: "",
	isLoggedIn: false,
	email: "",
	userRole: undefined,
	roles: [],
	firstName: "",
	lastName: "",
	nickName: "",
	employmentDate: "",
	accessToken: "",
	language: "",
	refreshToken: "",
	organizationId: "",
	organizationName: "",
	assignedDepartments: [],
	salaryHistory: [],
	bonusHistory: [],
	colorId: "",
	holidays: [],
	totalHolidays: 0,
	permissions: [],
	isOwner: false,
	isFirebaseLogin: false,
};

export const login = createAsyncThunk("auth/login", (loginCredentials: ILoginCredentials, thunkAPI) =>
	thunkHandler(postLogin(loginCredentials), thunkAPI)
);

const postLogin = async (userCredentials: ILoginCredentials) => {
	const { username, password } = userCredentials;
	const response = await axios.post<IUserCredentials>("api/Authenticate/login", {
		username: username,
		password: password,
	});

	switchLanguage(response.data.language);
	return response;
};

export const loginWithOAuth = async (cred?: Pick<UserCredential, "user">) => {
	if (!cred) return;
	const dispatch: AppDispatch = store.dispatch;
	const { setIsFirebaseLoggedIn, logInFirebase } = authSlice.actions;
	dispatch(setIsFirebaseLoggedIn(true));
	return axios
		.post("api/Authenticate/loginOAuth", cred.user)
		.then(({ data }) => {
			dispatch(logInFirebase(data));
			switchLanguage(data.language);
		})
		.catch(() => {
			dispatch(setIsFirebaseLoggedIn(false));
			showToast("toast.invalidLogin", "error");
			signOut(auth);
		});
};

export const logout = createAsyncThunk("auth/logout", async () => {
	await signOut(auth);
	const state: ApplicationState = store.getState();
	if (!state.auth.isFirebaseLogin) await axios.post("api/Authenticate/logout");
});

export const thunkHandler = async <T,>(asyncFn: Promise<AxiosResponse<T>>, thunkAPI: any) => {
	try {
		const response = await asyncFn;
		return response.data;
	} catch (error: any) {
		return thunkAPI.rejectWithValue(error.response.data);
	}
};

const authSlice = createSlice({
	name: "auth",
	initialState: INITIAL_STATE,
	reducers: {
		setIsFirebaseLoggedIn: (state, { payload }: PayloadAction<boolean>) => {
			state.isFirebaseLogin = payload; // interceptor will use auth.currentUser.getIdToken();
		},
		setTokens: (state, action: PayloadAction<IRefreshTokenCredentials>) => {
			state.accessToken = action.payload.accessToken;
			state.refreshToken = action.payload.refreshToken;
		},
		setDetails: (
			state,
			action: {
				payload: Pick<IUserCredentials, "firstName" | "lastName" | "phoneNumber" | "language">;
			}
		) => {
			state.firstName = action.payload.firstName;
			state.lastName = action.payload.lastName;
			state.phoneNumber = action.payload.phoneNumber;
			state.language = action.payload.language;
		},
		setRole: (state, action: { payload: { role: UserRoles } }) => {
			state.userRole = action.payload.role;
		},
		logInFirebase: (state, { payload }: PayloadAction<any>) => {
			Object.assign(state, {
				...payload,
				isLoggedIn: true,
				userRole: parseInt(payload.userRole),
				roles: [...payload.roles].sort((a, b) => a - b),
				isFirebaseLogin: true,
			} as IUserCredentials);
		},
	},
	extraReducers: (builder) => {
		builder.addCase(login.fulfilled, (state, action: any) => {
			Object.assign(state, {
				...action.payload,
				isLoggedIn: true,
				isFirebaseLogin: false,
				userRole: parseInt(action.payload.userRole),
				roles: [...action.payload.roles].sort((a, b) => a - b),
			} as IUserCredentials);
		});
		builder.addCase(logout.fulfilled, (state) => {
			Object.assign(state, INITIAL_STATE);
		});
	},
});

interface IRefreshTokenCredentials {
	accessToken: string;
	refreshToken: string;
}

export interface ILoginResponse {
	email: string;
	firstName: string;
	lastName: string;
	rol: string;
	accessToken: string;
	refreshToken: string;
}

export const { setTokens, setDetails, setRole } = authSlice.actions;

export default authSlice.reducer;
