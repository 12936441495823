const newProto = {
	...Date.prototype,
	isInvalid: function () {
		return this.getTime() !== this.getTime();
	},
};

const setDatePrototype = () => Object.setPrototypeOf(Date.prototype, newProto);

declare global {
	interface Date {
		isInvalid: () => boolean;
	}
}

export { setDatePrototype };
