import { createSlice } from "@reduxjs/toolkit";
import { CalendarDayInfo } from "../calendar/MonthlyView";

export type ICalendarSlice = {
  dayInformation: CalendarDayInfo[];
};

const INITIAL_STATE: ICalendarSlice = {
  dayInformation: [],
};

export const calendarSlice = createSlice({
  name: "calendar",
  initialState: INITIAL_STATE,
  reducers: {
    setCalendarDayInformation: (
      state,
      action: { payload: CalendarDayInfo[] }
    ) => {
      state.dayInformation = action.payload;
    },
  },
});

export const { setCalendarDayInformation } = calendarSlice.actions;
