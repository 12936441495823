import axios from "axios";
import { auth } from "index";
import { decreasePendingRequests, increasePendingRequests, show } from "shared/components/Toast/toastSlice";
import { handleLogout } from "./auth/authService";
import { ApplicationState } from "./redux/rootReducer";
import { EnhancedStore } from "@reduxjs/toolkit";
import { AnyAction } from "redux";

const interceptor = (store: EnhancedStore<any, AnyAction, any>) => {
    axios.interceptors.request.use(
        async (conf: any) => {
            const state: ApplicationState = store.getState();
            store.dispatch(increasePendingRequests());
            let accessToken = state.auth.accessToken;
            if (state.auth.isFirebaseLogin) accessToken = (await auth.currentUser?.getIdToken()) ?? "";
            conf.baseURL = `${window.location.origin}/`;
            conf.headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            return conf;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    axios.interceptors.response.use(
        (next) => {
            store.dispatch(decreasePendingRequests());
            return Promise.resolve(next);
        },
        (error) => {
            if (error.response.status === 401) {
                const state: ApplicationState = store.getState();
                if (state.auth.isLoggedIn) {
                    handleLogout();
                }
            }
            store.dispatch(decreasePendingRequests());

            if (error?.response?.data?.errors["Toast"]) {
                store.dispatch(
                    show({
                        message: error.response.data.errors["Toast"],
                        style: "error",
                    })
                );
            }

            return Promise.reject(error);
        }
    );
};

export default {
    interceptor,
};
