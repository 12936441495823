import { ILanguage } from "app/lang/ILanguage";
import React from "react";
import { useIntl } from "react-intl";
import { AnyString } from "shared/models/GlobalModels";
import { Path } from "shared/models/UtilityTypes";

export type TranslateReturnedObject = { translateMessage: (id: Path<ILanguage> | AnyString) => string };

const useTranslate = (): TranslateReturnedObject => {
	const intl = useIntl();

	const translateMessage = (id: string) => {
		return intl.formatMessage({ id });
	};

	return { translateMessage };
};
export default useTranslate;
