import { ILanguage } from "./ILanguage";

export const ro: ILanguage = {
	languages: {
		romanian: "Română",
		english: "Engleză",
	},
	errors: {
		isRequired: "obligatoriu.",
		isInvalid: "invalid.",
		requiredField: "Câmpul este obligatoriu.",
		invalidField: "Câmpul este invalid.",
		unique: "Această valoare este deja utilizată.",
		minimumLength: "Valoarea câmpului este prea scurtă.",
		maximumLength: "Valoarea câmpului este prea lungă.",
		notConfirmed: "Email neconfirmat",
		exceedsLimit: "cu cel mult",
		mustHaveAtLeast: "cu cel puțin",
		characters: "de caractere",
		minimumValue: "Valoarea trebuie să fie cel puțin",
		maximumValue: "Valoarea trebuie să fie mai mică decât",
		emailIsRegistered: "Emailul este deja înregistrat!",
		invalidDate: "Dată invalidă",
		emptyString: "Numele nu poate fi gol!",
		maxDateExceded: "Data maximă a fost depășită!",
	},
	toast: {
		loading: "Se încarcă...",
		successfullyRegistered: "Te-ai înregistrat cu succes, te rugăm să verifici căsuța de email.",
		failedRegister: "Înregistrarea a eșuat, te rugăm să încerci mai târziu.",
		emptyEmail: "Introduceți adresa de email.",
		emptyPassword: "Introduceți parola.",
		invalidLogin: "Email sau parolă invalidă.",
		resetPasswordEmail:
			"Emailul de resetare a parolei a fost trimis, te rugăm să verifici căsuța de email.",
		passwordHasBeenSet: "Parola a fost setată. Vei fi redirecționat în câteva secunde.",
		requestNoLongerValid: "Această solicitare nu mai este valabilă.",
		invalidSalary: "Salariu invalid.",
		selectRole: "Selectează cel puțin un rol pentru utilizator.",
		selectDepartment: "Angajatul trebuie să aibă atribuit cel puțin un departament.",
		agreeTerms: "Te rugăm, întâi acceptă termenii și condițiile.",
		succesfullyBusinessUnitDetails: "Ai actualizat cu succes datele unității comerciale.",
		succesfullyMyProfile: "Ai actualizat cu succes datele profilului tău!",
		succesfullyEmailSent: "Email-ul a fost trimis cu succes!",
	},
	prompt: {
		unsavedChanges: "Modificările tale nu vor fi salvate. Sigur dorești să părăsești pagina?",
	},
	common: {
		fields: {
			password: "Parolă",
			phoneNumber: "Nr. de telefon",
			language: "Limbă",
			firstName: "Prenume",
			lastName: "Nume",
			name: "Nume",
			nickName: "Poreclă",
			dailyRate: "Tarif zilnic",
			hourlyRate: "Tarif orar",
			headOfDepartment: "Șef de departament",
			focusMode: "Mod Focus",
			overtime: "Timp extra",
			color: "Atribuie o culoare",
			roles: "Roluri",
			employmentDate: "Data angajării",
			notes: "Notițe",
			date: "Data",
			businessUnitName: "Denumirea unității comerciale",
			address: "Adresă",
			vatNumber: "CUI",
		},
		actions: {
			submit: "Confirmă",
			cancel: "Înapoi",
			save: "Salvează",
			create: "Creează",
			edit: "Editează",
			delete: "Șterge",
			close: "Închide",
			duplicate: "Duplică",
			back: "Înapoi",
		},
		header: {
			settings: "Setări",
		},
		registrationSuccess: {
			thankYou: "Vă mulțumim pentru înregistrare!",
			emailSent:
				"E-mailul de înregistrare a fost trimis! Te rugăm să verifici adresa de e-mail pentru a confirma contul!",
		},
		register: {
			register: "Înregistrează-te",
			agree: "Sunt de acord cu",
			alreadyRegistered: "Te-ai înregistrat deja?",
			loginNow: "Conectează-te acum",
			signupWithFacebook: "Înregistrează-te cu Facebook",
			signupWithGoogle: "Înregistrează-te cu Google",
		},
		login: {
			login: "Conectează-te",
			loginWithGoogle: "Conectează-te cu Google",
			forgotPassword: "Ai uitat parola?",
			loginWithFacebook: "Conectează-te cu Facebook",
			orSignInWithEmail: "sau folosește-ți emailul",
			rememberMe: "Ține-mă minte",
			noAccount: "Nu ai un cont?",
			registerNow: "Înregistrează-te acum!",
		},
		logout: {
			logout: "Părăsește",
		},
		terms: {
			termsAndConditions: "termenii și condițiile",
		},
		requestPassword: {
			title: "Te rugăm să completezi adresa de email pentru a reseta parola",
		},
		generateWorkSchedule: {
			title: "Generează program de lucru",
		},
		setPassword: {
			title: "Setează parola",
			confirmPassword: "Confirmă parola",
			passwordsDoNotMatch: "Parolele nu sunt identice.",
			tooShort: "Parola trebuie să fie de cel putin 6 caractere.",
			requiresDigit: "Parola trebuie să  conțină o cifră.",
			requiresLower: "Parola trebuie să conțină o literă mică.",
			requiresUpper: "Parola trebuie să conțină o literă mare.",
			nonAlphanumeric: "Parola trebuie să conțină un caracter non alfanumeric.",
			uniqueChars: "Parola trebuie să conțină caractere diferite.",
			passwordRequirements1: "Definește parola.",
			passwordRequirements2: "Pentru siguranța datelor folosiți o parolă complexă.",
			passwordRequirements3: "Parola trebuie să conțină cel puțin 6 caractere, dintre",
			passwordRequirements4: "care cel puțin o litera majusculă, una minusculă, un",
			passwordRequirements5: "caracter special și o cifră.",
		},
		profile: {
			profile: "Profil",
			details: "Date personale",
			assignedOrganization: "Organizație atribuită",
			title: "Detaliile mele",
			resetPassword: "Resetează parola",
			updated: "Profilul a fost actualizat cu succes!",
		},
	},
	role: {
		superadmin: {
			menu: {
				organizations: "Organizații",
				users: "Utilizatori",
			},
			unit: {
				unit: "Unitate comercială",
				unitName: "Numele Unității Comerciale",
			},
			organizations: {
				organization: "Organizație",
				create: "Creează o organizație",
				edit: "Editează organizația",
				createSuccess: "Organizația a fost creată cu succes.",
				editSuccess: "Organizația a fost editată cu succes.",
			},
			users: {
				create: "Creează un admin",
				edit: "Editează admin",
				createSuccess: "Utilizatorul a fost creat cu succes.",
				editSuccess: "Utilizatorul a fost editat cu succes.",
				deleteUserWarningBody:
					"Această acțiune nu poate fi anulată. Odată ce un utilizator a fost șters, acesta nu mai poate fi recuperat.",
				deleteUserWarningTitle: "Sigur dorești să ștergi acest utilizator?",
			},
		},
		admin: {
			menu: {
				calendar: "Calendar",
				employees: "Angajați",
				settings: "Setări",
				checkIn: "Prezența",
			},
			sidemenu: {
				shiftManagement: "Managementul Programului",
				departments: "Departamente",
				accountSettings: "Setări cont",
				units: "Detaliile Unității Comerciale",
			},
			shifts: {
				title: "Managementul Programului",
				create: "Creează o configurație",
				saveSuccess: "Configurările au fost salvate.",
				saveFailed: "Unele configurări sunt invalide.",
				cannotDelete: "Configurarea este in uz.",
				numberOfShifts: "Numărul de schimburi",
				shift: "Tură",
				configuration: "Configurarea turei",
				delete: "Șterge tură",
				deleteWarning: "Sigur dorești să ștergi această tură?",
				emptyShift:
					"Crează detaliile programului tău care se potrivesc afacerii tale și atribuiei departamentele.",
				template: "Șablon",
				layout: "Amplasare",
				startDate: "Data începerii",
				generate: "Generează",
				generateTooltip: "Angajatul are deja o configurație în alt departament.",
				shift2by2: "2 cu 2 | 1 tură / zi",
				shift3by3: "3 cu 3 | 1 tură / zi",
			},
			departments: {
				title: "Departamente",
				create: "Creează un departament",
				saveSuccess: "Configurările au fost salvate.",
				saveFailed: "Unele configurări sunt invalide.",
				billable: "Achitabil",
				assign: "Atribuie departamente",
				allAssigned: "Toate departamentele au fost atribuite deja.",
				delete: "Șterge departament",
				deleteWarning: "Sigur dorești să ștergi acest departament?",
				notEmployee: "Utilizatorul trebuie să aibă rolul de Angajat.",
				noDepartment: "Niciun departament disponibil, te rugăm să creezi unul",
				emptyDepartment: "Creează primul tău departament și atribuie-i un program!",
				noEmployeesInDepartment:
					"Departamentul tău nu are angajați. Te rog adaugă minim un angajat departamentului.",
			},
			employees: {
				owner: "Proprietar",
				employee: "Angajat",
				manager: "Administrator",
				list: "Listă",
				summary: "Sumar",
				create: "Creează",
				edit: "Editează angajatul",
				delete: "Vrei ca angajatul să fie șomer?!",
				personalDetails: "Detalii personale",
				financialDetails: "Detalii financiare",
				addSalary: "Adaugă un salariu",
				editSalary: "Editează salariul",
				salary: "Salariu",
				addBonus: "Adaugă un bonus",
				editBonus: "Editează bonusul",
				bonus: "Bonus",
				from: "Incepând din",
				to: "Pana în",
				givenOn: "Dat pe",
				willBeGivenOn: "Va fi dat pe",
				lastUpdated: "Ultima actualizare",
				dailyRate: "Tarif zilnic",
				hourlyRate: "Tarif orar",
				workingDays: "Zile lucrătoare",
				workingHours: "Ore lucrătoare",
				dailyWorkingHours: "Ore lucrătoare zilnice",
				shiftsAssigned: "Ture alocate",
				freeWeekend: "Weekend liber",
				hoursAssigned: "Ore alocate",
				extraHours: "Ore extra",
				others: "Altele",
				grandTotal: "Total",
				times: "ori",
				holiday: "Concediu",
				forCurrentMonth: "Pentru luna curentă",
				emptyEmployee: "Adăugă primul angajat și configurează programul.",
				createEmployee: "Adaugă angajat",
				goToEmployees: "Vizualizează angajații",
			},
			holidays: {
				title: "Concedii",
				available: "Concedii disponibile",
				create: "Adaugă concediu",
				delete: "Șterge concediu",
				allAssigned: "Toate concediile au fost atribuite deja.",
				overlappingShifts:
					"Această configurație se suprapune cu niște ture de lucru. Confirmă dacă dorești să adaugi concediul și să elimini turele programate.",
				deleteWarning: "Sigur dorești să ștergi acest concediu?",
				invalidEndDate: "Data de încheiere nu poate fi mai devreme decât a cea de începere",
				invalidStartDate: "Data de începere nu poate fi înainte de data de azi sau cea de angajare",
				notEnoughAvailableHolidays: "Nu sunt suficiente zile disponibile",
				periodOverlap: "Se suprapune perioada!",
				invalidDate: "Dată invalidă!",
				notCurrentYear: "Data selectată nu poate avea anul diferit de cel actual!",
			},
			calendar: {
				startConfig: "Creează un program și un departament întâi!",
				goToSettings: "Începe",
				clear: "Sigur dorești să ștergi configurația acestei luni?",
				shiftDeleteOptions: "Selectează una dintre opțiunile următoare:",
				deleteHead: "Șterge Șef de departament",
				deleteShift: "Șterge tura în întregime",
				employeeNoLongerWorking: "Acest angajat nu mai lucrează în acest departament",
			},
		},
	},
};
