import { createSlice } from "@reduxjs/toolkit";

export type ToastStyle = "error" | "warning" | "info" | "success";

interface IState {
  open: boolean;
  message: string;
  style: ToastStyle;
  requestsPending: number;
}

const INITIAL_STATE: IState = {
  open: false,
  message: "",
  style: "success",
  requestsPending: 0,
};

export const toastSlice = createSlice({
  name: "toast",
  initialState: INITIAL_STATE,
  reducers: {
    setStyle: (state, action: { payload: { style: ToastStyle } }) => {
      state.style = action.payload.style;
    },
    show: (
      state,
      action: {
        payload: { message: string | any; style?: ToastStyle };
      }
    ) => {
      state.message = action.payload.message;
      state.style = action.payload.style || "success";
      state.open = true;
    },
    hide: (state) => {
      state.open = false;
    },
    increasePendingRequests: (state) => {
      state.requestsPending++;
    },
    decreasePendingRequests: (state) => {
      state.requestsPending = Math.max(0, state.requestsPending - 1);
    },
  },
});

export const {
  hide,
  show,
  setStyle,
  increasePendingRequests,
  decreasePendingRequests,
} = toastSlice.actions;
