import { createTheme } from "@mui/material";

export const theme = createTheme({
	palette: {
		primary: {
			main: "#3D8276",
			dark: "#30675D",
			light: "#7A799C",
		},
	},
});
