import { Grid } from "@mui/material";
import { ThemeProvider } from "@mui/material";
import * as React from "react";
import { theme } from "./Themes";

export default (props: { children?: React.ReactNode }) => (
  <React.Fragment>
    <ThemeProvider theme={theme}>
      <Grid container>
        <Grid container item>
          {props.children}
        </Grid>
      </Grid>
    </ThemeProvider>
  </React.Fragment>
);
