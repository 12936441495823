import * as React from "react";
import Layout from "./Layout";

import { useEffect } from "react";
import { handleRefreshTimerOnRefreshPage } from "./auth/authService";
import { useSelector } from "react-redux";
import { ApplicationState } from "./redux/rootReducer";
import { RenderComponentSubroutes } from "./routes/routeHelpers";
import { UserRoles } from "./auth/UserRoles";
import { IntlProvider } from "react-intl";
import { ro } from "./lang/ro-RO";
import { en } from "./lang/en-EN";
import { Languages } from "./lang/Languages";
import { flattenMessages } from "shared/helpers/flattenJSON";
import Toast from "shared/components/Toast/Toast";
import moment from "moment";
import "moment/locale/ro";
import "moment/locale/en-gb";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "index";
import { switchLanguage } from "shared/helpers/getLanguages";

export default () => {
	const user = useSelector((state: ApplicationState) => state.auth);
	const [userRoutes, setUserRoutes] = React.useState<any[]>([]);
	const [localizedText, setLocalizedText] = React.useState<any>(en);
	const languageCode = useSelector((state: ApplicationState) => state.language.languageCode);
	const [firebaseUser, loading, error] = useAuthState(auth);

	useEffect(() => {
		handleRefreshTimerOnRefreshPage(user);
	}, []);

	useEffect(() => {
		if (languageCode === Languages.English) {
			setLocalizedText(en);
			moment.locale("en");
		} else if (languageCode === Languages.Romanian) {
			setLocalizedText(ro);
			moment.locale("ro");
		}
	}, [languageCode]);

	useEffect(() => {
		if (user.userRole == undefined) {
			// Load routes for users that are not logged in
			import(`./routes/byRole/none`).then((data) => {
				setUserRoutes(data.routes);
			});
		} else {
			// Load routes based on the role of the user logged in
			const userRoleString = UserRoles[user.userRole].toString().toLocaleLowerCase();
			import(`./routes/byRole/${userRoleString}`).then((data) => {
				setUserRoutes(data.routes);
			});
		}
	}, [user.userRole]);

	return (
		<Layout>
			<IntlProvider locale={navigator.language} messages={flattenMessages(localizedText)}>
				{user.isFirebaseLogin && !loading && !error && RenderComponentSubroutes(userRoutes)}
				{!user.isFirebaseLogin && RenderComponentSubroutes(userRoutes)}
				<Toast />
			</IntlProvider>
		</Layout>
	);
};
