import React, { ReactElement, useEffect, useRef } from "react";
import { Snackbar, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { useDispatch, useSelector } from "react-redux";
import { hide, show } from "./toastSlice";
import MuiAlert from "@mui/lab/Alert";
import { makeStyles } from "@mui/styles";
import useTranslate from "shared/hooks/useTranslate";

const useStyles = makeStyles({
	info: {
		background: "#3D8276",
		boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
	},
	error: {
		background: "#E75353",
	},
	success: {
		background: "#4AC164",
	},
	circle: {
		color: "#fff",
	},
});

function SlideTransition(props: TransitionProps) {
	return <Slide {...props} children={props.children as ReactElement<any, any>} direction="up" />;
}

export default function TransitionsSnackbar() {
	const classes = useStyles();
	const isOpen = useSelector((state: any) => state.toast.open);
	const requestsPending = useSelector((state: any) => state.toast.requestsPending);
	const { translateMessage } = useTranslate();
	let toastMessage = useSelector((state: any) => state.toast.message);

	if (toastMessage && translateMessage(toastMessage)) {
		toastMessage = translateMessage(toastMessage);
	}

	const toastStyle = useSelector((state: any) => state.toast.style);
	const dispatch = useDispatch();
	const RequestTimer = useRef<any>(null);
	const ShowTimer = useRef<any>(null);
	const [state, setState] = React.useState<{
		Transition: any;
	}>({
		Transition: SlideTransition,
	});

	useEffect(() => {
		if (isOpen == true) {
			if (ShowTimer.current) {
				clearTimeout(ShowTimer.current);
			}
			ShowTimer.current = setTimeout(() => {
				dispatch(hide());
			}, 4000);
		}
	}, [isOpen, toastStyle]);

	useEffect(() => {
		if (requestsPending > 0 && !isOpen) {
			RequestTimer.current = setTimeout(() => {
				dispatch(
					show({
						message: "toast.loading",
						style: "info",
					})
				);
			}, 300);
		} else {
			clearTimeout(RequestTimer.current);
			RequestTimer.current = null;
		}

		return () => {
			clearTimeout(RequestTimer.current);
		};
	}, [requestsPending]);

	return (
		<React.Fragment>
			<Snackbar
				id="snackbar"
				open={isOpen}
				TransitionComponent={state.Transition}
				key={state.Transition.name}
				autoHideDuration={3000}
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
			>
				<MuiAlert
					icon={false}
					elevation={6}
					variant="filled"
					severity={toastStyle || "success"}
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						width: "auto",
						minWidth: 300,
						fontSize: 16,
					}}
					classes={{
						filledInfo: classes.info,
						filledSuccess: classes.success,
						filledError: classes.error,
					}}
					children={<span>{toastMessage}</span>}
				/>
			</Snackbar>
		</React.Fragment>
	);
}
