import { Languages, StringIndexedLanguages } from "app/lang/Languages";
import { changeLanguage } from "app/lang/languageSlice";
import store from "../../index";

export const getLanguages = () => {
	const lang = Object.keys(Languages).map((language) => {
		return {
			language,
			code: Languages[language],
		};
	});
	return lang;
};

export const languageLookup = (translateMessage: Function) => {
	const lookupValues = getLanguages().map((language) => {
		return {
			id: language.code,
			name: translateMessage(`languages.${language.language.toLocaleLowerCase()}`),
		};
	});
	return lookupValues;
};

export const switchLanguage = (languageCode: string) => {
	const language = StringIndexedLanguages[languageCode];
	store.dispatch(
		changeLanguage({
			displayedLanguage: language,
			languageCode,
		})
	);
};
