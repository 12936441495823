declare global {
	interface Array<T> {
		last(): T | undefined;
		max(): T extends number ? T | undefined : never;
	}
}

const newPrototype = {
	...Array.prototype,
	last() {
		if (this.length == 0) return undefined;
		return this[this.length - 1];
	},
	max() {
		if (this.length == 0) return undefined;
		let max = this[0];
		for (let i = 1; i < this.length; i++) if (this[i] > max) max = this[i];
		return max;
	},
};

export const setArrayPrototype = () => {
	Object.setPrototypeOf(Array.prototype, newPrototype);
};
