import { combineReducers } from "redux";
import thunk from "redux-thunk";
import { ApplicationState, InitialReducers } from "./rootReducer";
import { AnyAction, configureStore, ConfigureStoreOptions } from "@reduxjs/toolkit";

export function createReducerManager(initialReducers: InitialReducers) {
	// Create an object which maps keys to reducers
	const reducers = { ...initialReducers };
	// Create the initial combinedReducer
	let combinedReducer = combineReducers(reducers);

	// An array which is used to delete state keys when reducers are removed
	let keysToRemove: string[] = [];

	return {
		getReducerMap: () => reducers,

		// The root reducer function exposed by this object
		// This will be passed to the store
		reduce: (state: any, action: any) => {
			// If any reducers have been removed, clean up their state first
			if (keysToRemove.length > 0) {
				state = { ...state };
				for (let key of keysToRemove) {
					delete state[key];
				}
				keysToRemove = [];
			}

			// Delegate to the combined reducer
			return combinedReducer(state, action);
		},

		// Adds a new reducer with the specified key
		add: (key: string, reducer: any) => {
			if (!key || reducers[key]) {
				return;
			}

			// Add the reducer to the reducer mapping
			reducers[key] = reducer;

			// Generate a new combined reducer
			combinedReducer = combineReducers(reducers);
		},

		// Removes a reducer with the specified key
		remove: (key: keyof typeof reducers) => {
			if (!key || !reducers[key]) {
				return;
			}

			// Remove it from the reducer mapping
			delete reducers[key];

			// Add the key to the list of keys to clean up
			keysToRemove.push(key);

			// Generate a new combined reducer
			combinedReducer = combineReducers(reducers);
		},
	};
}
export default function configureAppStore(
	reducers: ConfigureStoreOptions<any, AnyAction, any>["reducer"],
	initialState?: ApplicationState
) {
	const middleware = [thunk];

	return configureStore({
		reducer: reducers,
		preloadedState: initialState,
		middleware,
	});
}
