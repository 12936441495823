import { createSlice } from "@reduxjs/toolkit";

export interface ILanguageState {
	displayedLanguage: string;
	languageCode: string;
}

const INITIAL_STATE: ILanguageState = {
	displayedLanguage: "English",
	languageCode: "en-EN",
};

export const languageSlice = createSlice({
	name: "language",
	initialState: INITIAL_STATE,
	reducers: {
		changeLanguage: (state, action: { payload: ILanguageState }) => {
			state.displayedLanguage = action.payload.displayedLanguage;
			state.languageCode = action.payload.languageCode;
		},
	},
});

export const { changeLanguage } = languageSlice.actions;
