import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router } from "react-router";
import { createBrowserHistory } from "history";
import App from "./app/App";
import Interceptor from "./app/axiosInterceptor";
import configureStore, { createReducerManager } from "./app/redux/store";
import { reducers } from "./app/redux/rootReducer";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import "./index.css";
import "simplebar/dist/simplebar.min.css";
import setPrototypes from "shared/prototypes";
import { FirebaseOptions, initializeApp } from "firebase/app";
import { Auth, browserLocalPersistence, getAuth } from "firebase/auth";
import { getPerformance } from "firebase/performance";
// Create browser history to use in the Redux store
const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href") as string;
export const history = createBrowserHistory({ basename: baseUrl });

const store = configureStore(reducers);
const persistor = persistStore(store);

// Axios interceptor
Interceptor.interceptor(store);

setPrototypes();
fetch("/envconfig.json")
	.then((res) => res.json())
	.then((config: FirebaseOptions) => {
		const firebaseApp = initializeApp(config);
		auth = getAuth(firebaseApp);
		const perf = getPerformance(firebaseApp);
		auth.setPersistence(browserLocalPersistence);

		ReactDOM.render(
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<Router history={history}>
						<App />
					</Router>
				</PersistGate>
			</Provider>,
			document.getElementById("root")
		);
	});

export type AppDispatch = typeof store.dispatch;
export var auth: Auth;
    
export default store;
