import DepartmentConfig from "features/dashboards/admin/settings/departments/DepartmentConfig";
import { ILanguage } from "./ILanguage";

export const en: ILanguage = {
	languages: {
		romanian: "Romanian",
		english: "English",
	},
	errors: {
		isRequired: "is required.",
		isInvalid: "is invalid.",
		requiredField: "Field is required.",
		invalidField: "Field is invalid.",
		unique: "This value is already in use.",
		minimumLength: "Field value is too short.",
		maximumLength: "Field value is too long.",
		notConfirmed: "Email not confirmed",
		exceedsLimit: "exceeds the limit of",
		mustHaveAtLeast: "must have at least",
		characters: "characters",
		minimumValue: "Value must be at least",
		maximumValue: "Value must be less than",
		emailIsRegistered: "Email already registered!",
		invalidDate: "Invalid date",
		emptyString: "Name cannot be empty.",
		maxDateExceded: "Max date exceeded!",
	},
	toast: {
		loading: "Loading...",
		failedRegister: "The register failed, please try again later.",
		successfullyRegistered: "You have been successfully registered, please check your inbox.",
		emptyEmail: "Email input is empty.",
		emptyPassword: "Password input is empty.",
		invalidLogin: "Invalid email or password",
		resetPasswordEmail: "The password reset email has been sent, please check your inbox.",
		passwordHasBeenSet: "Your password has been set. You will be redirected in a few seconds.",
		requestNoLongerValid: "This request is no longer valid.",
		invalidSalary: "Invalid salary.",
		selectRole: "You must select at least one role for the user.",
		selectDepartment: "The employee must have at least one department assigned.",
		agreeTerms: "Please agree to the terms and conditions.",
		succesfullyBusinessUnitDetails: "You have been succesfully updated business unit details!",
		succesfullyMyProfile: "You have been succesfully updated your profile!",
		succesfullyEmailSent: "Email has been sent successfully!",
	},
	prompt: {
		unsavedChanges: "Your changes will not be saved. Are you sure you want to leave the page?",
	},
	common: {
		fields: {
			password: "Password",
			phoneNumber: "Phone Number",
			language: "Language",
			firstName: "First Name",
			lastName: "Last Name",
			name: "Name",
			nickName: "Nickname",
			dailyRate: "Daily rate",
			hourlyRate: "Hourly rate",
			headOfDepartment: "Head of department",
			focusMode: "Focus Mode",
			overtime: "Overtime",
			color: "Assign Color",
			roles: "Roles",
			employmentDate: "Employment Date",
			notes: "Notes",
			date: "Date",
			businessUnitName: "Business Unit Name",
			address: "Address",
			vatNumber: "VAT Number",
		},
		actions: {
			submit: "Confirm",
			cancel: "Cancel",
			save: "Save",
			create: "Create",
			edit: "Edit",
			delete: "Delete",
			close: "Close",
			duplicate: "Duplicate",
			back: "Back",
		},
		header: {
			settings: "Settings",
		},
		registrationSuccess: {
			emailSent:
				"Your registration email was sent! Please verify your email to confirm your account!",
			thankYou: "Thank you for registering!",
		},
		register: {
			register: "Register",
			agree: "I agree to the",
			alreadyRegistered: "Have you already registered?",
			loginNow: "Login now!",
			signupWithFacebook: "Sign Up with Facebook",
			signupWithGoogle: "Sign Up with Google",
		},
		login: {
			loginWithFacebook: "Login with Facebook",
			loginWithGoogle: "Login with Google",
			orSignInWithEmail: "or sign in with email",
			login: "Login",
			forgotPassword: "Forgot your password?",
			rememberMe: "Remember me",
			noAccount: "Don't have an account?",
			registerNow: "Register now!",
		},
		logout: {
			logout: "Logout",
		},
		terms: {
			termsAndConditions: "terms and conditions",
		},
		requestPassword: {
			title: "Please give us your email address to reset your password",
		},
		generateWorkSchedule: {
			title: "Generate work schedule",
		},
		setPassword: {
			title: "Set password",
			confirmPassword: "Confirm Password",
			passwordsDoNotMatch: "Passwords do not match.",
			tooShort: "Passwords must be at least 6 characters.",
			requiresDigit: "Password must contain a digit.",
			requiresLower: "Password must contain a lower letter.",
			requiresUpper: "Password must contain an upper letter.",
			nonAlphanumeric: "Password must contain a non alphanumeric character.",
			uniqueChars: "Password must contain different characters.",
			passwordRequirements1: "Define a password.",
			passwordRequirements2: "For security reasons, define a strong password.",
			passwordRequirements3: "Your password must contain at least 6 characters, of",
			passwordRequirements4: "which one must be uppercase, one lowercase, have a",
			passwordRequirements5: "special character and a digit.",
		},
		profile: {
			profile: "Profile",
			details: "Personal details",
			assignedOrganization: "Assigned organization",
			title: "My details",
			resetPassword: "Reset password",
			updated: "Your profile has been updated",
		},
	},
	role: {
		superadmin: {
			menu: {
				organizations: "Organizations",
				users: "Users",
			},
			unit: {
				unit: "Commercial Unit",
				unitName: "Commercial Unit's Name",
			},
			organizations: {
				organization: "Organization",
				create: "Create organization",
				edit: "Edit organization",
				createSuccess: "Organization created successfully.",
				editSuccess: "Organization edited successfully.",
			},
			users: {
				create: "Create admin",
				edit: "Edit admin",
				createSuccess: "User created successfully.",
				editSuccess: "User edited successfully.",
				deleteUserWarningBody:
					"This action can't be undone. Once a user has been deleted, it cannot be recovered.",
				deleteUserWarningTitle: "Are you sure you want to delete this user?",
			},
		},
		admin: {
			menu: {
				calendar: "Calendar",
				employees: "Employees",
				settings: "Settings",
				checkIn: "Check In",
			},
			sidemenu: {
				shiftManagement: "Schedule management",
				departments: "Departments",
				accountSettings: "Account settings",
				units: "Business Unit Details",
			},
			shifts: {
				title: "Schedule management",
				create: "Create Schedule",
				saveSuccess: "Configurations saved.",
				saveFailed: "Some configurations are invalid.",
				cannotDelete: "Configuration in use.",
				numberOfShifts: "Number of shifts",
				shift: "Shift",
				configuration: "Shift configuration",
				delete: "Remove Shift",
				deleteWarning: "Are you sure you want to remove this shift?",
				emptyShift:
					"Create schedule details that suit your business and assign it to your departments",
				layout: "Layout",
				template: "Template",
				startDate: "Start Date",
				generate: "Generate",
				generateTooltip: "Employee has shifts assigned in another department.",
				shift2by2: "2 by 2 | 1 shift / day",
				shift3by3: "3 by 3 | 1 shift / day",
			},
			departments: {
				title: "Departments",
				create: "Create department",
				saveSuccess: "Configurations saved.",
				saveFailed: "Some configurations are invalid.",
				billable: "Billable",
				assign: "Assign departments",
				allAssigned: "All departments have already been assigned.",
				delete: "Remove Department",
				deleteWarning: "Are you sure you want to remove this department?",
				notEmployee: "The user must have the Employee role.",
				noDepartment: "No departments available, please create one",
				emptyDepartment: "Create your first department then assign it a schedule!",
				noEmployeesInDepartment:
					"Your department has no employees. Please add at least one employee.",
			},
			employees: {
				owner: "Owner",
				employee: "Employee",
				manager: "Manager",
				list: "List",
				summary: "Overview",
				create: "Create",
				edit: "Edit employee",
				delete: "You want the employee to be unemployed?!",
				personalDetails: "Personal details",
				financialDetails: "Financial details",
				addSalary: "Add a salary",
				editSalary: "Edit the salary",
				salary: "Salary",
				addBonus: "Add a bonus",
				editBonus: "Edit the bonus",
				bonus: "Bonus",
				from: "From",
				to: "To",
				givenOn: "Given on",
				willBeGivenOn: "Will be given on",
				lastUpdated: "Last updated",
				dailyRate: "Daily rate",
				hourlyRate: "Hourly rate",
				workingDays: "Working days",
				workingHours: "Working hours",
				dailyWorkingHours: "Daily working hours",
				shiftsAssigned: "Shift assigned",
				freeWeekend: "Free weekend",
				hoursAssigned: "Hours assigned",
				extraHours: "Extra hours",
				others: "Others",
				grandTotal: "Grand Total",
				times: "times",
				holiday: "Holiday",
				forCurrentMonth: "For the current month",
				emptyEmployee: "Add your first employee and let's configure his schedule!",
				createEmployee: "Add Employee",
				goToEmployees: "Go to Employees",
			},
			holidays: {
				title: "Holidays",
				available: "Available Holidays",
				create: "Add Holiday",
				delete: "Remove Holiday",
				allAssigned: "All holidays have already been assigned.",
				overlappingShifts:
					"This holiday configuration overlaps some shifts. Confirm if you want to add this holiday and remove the scheduled shifts.",
				deleteWarning: "Are you sure you want to remove this holiday?",
				invalidEndDate: "End date can't be earlier than start date",
				invalidStartDate: "Start date cannot be earlier than today or the employment date",
				notEnoughAvailableHolidays: "Not enough available holidays",
				periodOverlap: "Period overlap!",
				invalidDate: "Invalid dates!",
				notCurrentYear: "The selected date cannot have a different year than the current one!",
			},
			calendar: {
				startConfig: "Create a shift and a department to get started!",
				goToSettings: "Get started",
				clear: "Are you sure you want to clear this month configuration?",
				shiftDeleteOptions: "Select one of the following options:",
				deleteHead: "Remove the head of department",
				deleteShift: "Remove the shift entirely",
				employeeNoLongerWorking: "This employee no longer works in this department",
			},
		},
	},
};
