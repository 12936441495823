import { ILoginCredentials } from "shared/models/ILoginCredentials";
import { unwrapResult } from "@reduxjs/toolkit";
import { login, logout, setTokens } from "./authSlice";
import store, { AppDispatch } from "index";
import { IUserCredentials } from "shared/models/IUserCredentials";
import axios, { AxiosResponse } from "axios";
import jwt, { JwtPayload } from "jsonwebtoken";
import { ApplicationState } from "app/redux/rootReducer";
import { switchLanguage } from "shared/helpers/getLanguages";
import { MINUTE, SECOND } from "shared/constants/time";

type Language = {
	language: string;
};
type IUserCredentialsWithLanguage = IUserCredentials & Language;

export const handleLogin = (formValues: ILoginCredentials): Promise<IValidationError | null> => {
	const dispatch = store.dispatch as any;

	var promise = new Promise<IValidationError | null>((resolve, reject) => {
		dispatch(login(formValues))
			.then(unwrapResult)
			.then((userCredentials: IUserCredentialsWithLanguage) => {
				setRefreshTimer(userCredentials.accessToken);
				resolve(null);
				switchLanguage(userCredentials.language);
			})
			.catch((errors: any) => {
				reject(errors as IValidationError);
			});
	});

	return promise;
};

export const handleLogout = () => {
	const dispatch = store.dispatch as any;

	dispatch(logout()).then(() => {
		clearTimeout(refreshTimer);
	});
};

export const handleRefreshTimerOnRefreshPage = (user: IUserCredentials) => {
	if (user.isLoggedIn && !user.isFirebaseLogin && user.accessToken) {
		setRefreshTimer(user.accessToken);
	}
};

let refreshTimer: NodeJS.Timeout;
const setRefreshTimer = (accessToken: string) => {
	const dispatch: AppDispatch = store.dispatch;
	const refreshRemainingTime = getRefreshRemainingTime(accessToken);
	clearTimeout(refreshTimer);
	refreshTimer = setTimeout(() => {
		const state: ApplicationState = store.getState();
		if (state.auth.isFirebaseLogin) return;
		axios
			.post(`api/Authenticate/refresh-token`, {
				refreshToken: state.auth.refreshToken,
				accessToken: state.auth.accessToken,
			})
			.then((res: AxiosResponse<IRefreshTokenCredentials>) => {
				if (res.status === 200) {
					dispatch(setTokens(res.data));
					setRefreshTimer(res.data.accessToken);
				} else {
					handleLogout();
				}
			});
	}, refreshRemainingTime);
};

interface IRefreshTokenCredentials {
	accessToken: string;
	refreshToken: string;
}

export interface IValidationError {
	errors: [];
}

const getRefreshRemainingTime = (token: string) => {
	const decode: any = jwt.decode(token, { complete: true });
	const MIN_DELAY_TIME = 1 * SECOND;
	if (!decode) return MIN_DELAY_TIME;
	const NOW = Date.now();
	const tokenExpiresOn = decode.payload.exp * 1000;

	const timeUntilExpiration = tokenExpiresOn - NOW;
	return Math.max(timeUntilExpiration - 5 * MINUTE, MIN_DELAY_TIME);
};
