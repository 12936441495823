import * as React from "react";
import { Route, Switch } from "react-router";

const RenderLoading = () => {
	return <div></div>;
};

export function RenderComponentSubroutes(routes: any) {
	return (
		<React.Suspense fallback={RenderLoading()}>
			<Switch>
				{routes.map((route: any, i: any) => (
					<RouteWithSubRoutes key={`route-${i}`} {...route} />
				))}
			</Switch>
		</React.Suspense>
	);
}

export function RouteWithSubRoutes(route: any) {
	document.title = "SHIFTMNG";
	return (
		<Route
			exact={route.exact}
			path={route.path}
			render={(props) => (
				// pass the sub-routes down to keep nesting
				<route.component {...props} routes={route.routes} />
			)}
		/>
	);
}
