import { hide, show, ToastStyle } from "../components/Toast/toastSlice";
import store from "index";

export const showToast = (message: string, style?: ToastStyle) => {
  store.dispatch(show({ message: message, style: style || "success" }));
};

export const hideToast = () => {
  store.dispatch(hide());
};
