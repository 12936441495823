import authReducer from "../auth/authSlice";
import storageSession from "redux-persist/lib/storage/session";
import { toastSlice } from "../../shared/components/Toast/toastSlice";
import { persistCombineReducers, PersistConfig, persistReducer, REHYDRATE } from "redux-persist";
import { encryptTransform } from "redux-persist-transform-encrypt";
import { IUserCredentials } from "shared/models/IUserCredentials";
import { ILanguageState, languageSlice } from "app/lang/languageSlice";
import { calendarSlice, ICalendarSlice } from "features/dashboards/admin/shared/calendarSlice";

// The top-level state object
export interface ApplicationState {
	auth: IUserCredentials;
	language: ILanguageState;
	calendar: ICalendarSlice;
}

const persistConfig: PersistConfig<any> = {
	key: "root",
	storage: storageSession,
	transforms: [
		encryptTransform({
			secretKey: "XezWL#mcvo3dSjlkxFoDRroxcATO#b8m6EHgou%XhhPjQ6$Lfq",
		}),
	],
	blacklist: ["toast", "calendar"],
};

export const reducers = persistCombineReducers(persistConfig, {
	auth: authReducer,
	toast: toastSlice.reducer,
	language: languageSlice.reducer,
	calendar: calendarSlice.reducer,
});
export type InitialReducers = typeof reducers;

export interface AppThunkAction<TAction> {
	(dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
